<!--
 * @Author: dingguowei
 * @Date: 2023-06-05 18:07:22
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-18 14:55:34
-->
<template>
  <div class="home">
    <div class="publish">
      <img :src="pubimgUrl" alt="" @click="publishTask" />
      <span>发布任务</span>
    </div>
    <div class="option">
      <el-input v-model="taskName" placeholder="任务名称" size="mini" style="width: 8rem; margin-right: 2rem"></el-input>
      <el-select v-model="taskType" clearable placeholder="任务状态" size="mini" style="width: 8rem; margin-right: 2rem">
        <el-option label="全部" :value="null"> </el-option>
        <el-option label="已上架" :value="1"> </el-option>
        <el-option label="已下架" :value="0"> </el-option>
        <el-option label="关闭" :value="2"> </el-option>
      </el-select>
      <el-date-picker size="mini" v-model="dateRange" type="datetimerange" range-separator="至" start-placeholder="任务开始日期"
        end-placeholder="任务结束日期" value-format="yyyy-MM-dd HH:mm:ss" style="margin-right: 2rem">
      </el-date-picker>
      <el-button size="mini" type="primary" @click="search(true)">搜索</el-button>
      <el-button size="mini" type="primary" @click="reset">重置</el-button>
    </div>
    <div class="tabledata">
      <el-table :key="tableKey" :data="tableData" style="width: 100%; font-size: 0.8rem; flex: 1" v-loading="loading"
        height="100%">
        <el-table-column label="序号" type="index" width="60" align="center" :show-overflow-tooltip="true">
        </el-table-column>

        <el-table-column label="任务名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.title }}
          </template>
        </el-table-column>
        <el-table-column label="状态" width="120" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div class="status">
              <div class="circle" :style="{ background: statusColor(scope.row.status) }"></div>
              <span>{{ statusText(scope.row.status) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="领取人数" width="80" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.receivedNum }}人
          </template>
        </el-table-column>
        <el-table-column label="提交人数" width="80" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.submittedNum }}人
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="提现天数"
          width="100"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope"> {{ scope.row.cashDays }}天 </template>
        </el-table-column> -->
        <el-table-column label="任务开始时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.startAt ? scope.row.startAt : "暂无" }}
          </template>
        </el-table-column>
        <el-table-column label="任务结束时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.endAt ? scope.row.endAt : "暂无" }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="380">
          <template slot-scope="scope">
            <!-- 下架状态 -->
            <template v-if="scope.row.status === 0">
              <span class="handel" @click="auditTask(scope.row.id)">审核任务</span>
              <el-divider direction="vertical"></el-divider>
              <span class="handel" @click="taskdetails(scope.row.id)">查看详情</span>
              <el-divider direction="vertical"></el-divider>
              <span class="handel" @click="upTask(scope.row.id)">上架任务</span>
              <el-divider direction="vertical"></el-divider>
              <span class="handel" @click="closeTask(scope.row.id)">关闭任务</span>
            </template>
            <!-- 上架状态 -->
            <template v-else-if="scope.row.status === 1">
              <span class="handel" @click="auditTask(scope.row.id)">审核任务</span>
              <el-divider direction="vertical"></el-divider>
              <span class="handel" @click="taskdetails(scope.row.id)">查看详情</span>
              <el-divider direction="vertical"></el-divider>
              <span class="handel" @click="downTask(scope.row.id)">下架任务</span>
              <el-divider direction="vertical"></el-divider>
              <span class="handel" @click="closeTask(scope.row.id)">关闭任务</span>
            </template>
            <!-- 关闭状态 -->
            <template v-else-if="scope.row.status === 2">
              <span class="handel" @click="taskdetails(scope.row.id)">查看详情</span>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="pagination" background layout="prev, pager, next" :total="total"
        @current-change="handleCurrentChange" :current-page.sync="pageNo" :page-size="pageSize">
      </el-pagination>
    </div>
  </div>
</template>



<script>
import store from "@/store";
import { brandGetTask } from "@/api/home";
import { gettaskInfo, upTask, downTask, closeTask } from "@/api/home";
import router from "@/router";

export default {
  components: {},
  data() {
    return {
      // 选项栏
      taskType: null,
      dateRange: [],
      taskName: "",
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      tableKey: true,
      loading: false,
    };
  },
  created() { },
  mounted() {
    this.search();
  },
  watch: {},
  computed: {
    pubimgUrl() {
      return require("@/assets/home/publishmark.png");
    },
    statusColor() {
      return function (val) {
        if (val === 1) {
          return "#67C23A";
        } else if (val === 0) {
          return "#E6A23C";
        } else {
          return "#F56C6C";
        }
      };
    },
    statusText() {
      return function (val) {
        if (val === 1) {
          return "已上架";
        } else if (val === 0) {
          return "已下架";
        } else if (val === 2) {
          return "关闭";
        }
      };
    },
  },
  methods: {
    async search(val) {
      this.loading = true;
      await brandGetTask({
        endAt: this.dateRange[1],
        kwd: this.taskName,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        startAt: this.dateRange[0],
        status: this.taskType,
      }).then((res) => {
        this.loading = false;
        this.tableData = res.data.records;
        this.tableKey = !this.tableKey
        this.total = res.data.total;
        if (val) {
          this.$message({
            message: "搜索成功！",
            type: "success",
          });
        }
      });
    },
    // 重置
    reset() {
      (this.taskName = ""), (this.taskType = null), (this.dateRange = []);
    },
    // 查看任务详情
    taskdetails(val) {
      store.commit("task/CHANGE_MISSIONID", val);
      router.push({ name: "发布任务详情" });
    },
    // 查看该发布任务下的审核任务管理
    auditTask(val) {
      store.commit("task/CHANGE_MISSIONID", val);
      router.push({ name: "审核任务管理" });
    },
    // 发布任务
    publishTask() {
      router.push({ name: "发布任务" });
    },
    handleCurrentChange() {
      this.search();
    },
    async upTask(val) {
      await upTask({
        missionId: val,
      }).then((res) => {
        if (res.code == 1000) {
          this.$message({
            message: "成功上架",
            type: "success",
          });
          this.search();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    async downTask(val) {
      await downTask({
        missionId: val,
      }).then((res) => {
        if (res.code == 1000) {
          this.$message({
            message: "成功下架",
            type: "success",
          });
          this.search();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    async closeTask(val) {
      await closeTask({
        missionId: val,
      }).then((res) => {
        if (res.code == 1000) {
          this.$message({
            message: "成功关闭",
            type: "success",
          });
          this.search();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  color: black;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem 1rem 2rem;

  ::v-deep .el-input__inner {
    border: none;
    border-radius: 14px;
  }

  ::v-deep .el-button {
    border: none;
    background-color: #fff;
    border-radius: 14px;
    color: #000;
  }

  ::v-deep .el-button--primary.is-active,
  .el-button--primary:active {
    background: #587aef;
    color: #fff;
  }

  ::v-deep .el-button--primary:focus,
  .el-button--primary:hover {
    background: #587aef;

    color: #fff;
  }

  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgb(28, 28, 28);
    /*进行修改选中项背景和字体*/
    color: #fff;
  }

  ::v-deep .el-table th.el-table__cell>.cell {
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
  }

  .publish {
    position: relative;

    & img {
      cursor: pointer;
      width: 26rem;
      height: 8rem;
    }

    & span {
      font-size: 1.5rem;
      position: absolute;
      left: 3rem;
      top: 3rem;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #f4f6fa;
      line-height: 2rem;
    }
  }

  .option {
    padding: 6px 0;
    display: flex;
    justify-content: flex-end;
  }

  .tabledata {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .status {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .circle {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        margin-right: 4px;
      }
    }

    .handel {
      cursor: pointer;
    }

    .handel:hover {
      color: #587aef;
    }

    .pagination {
      margin-top: 10px;
      margin-left: auto;
    }
  }
}
</style>